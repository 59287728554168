// Components

import App from "./App.vue";

// Composables
import { createApp, h } from "vue";

const app = createApp({
    render: () => h(App),
});

app.mount("#app");
