<template>
  <div ref="view"/>
</template>

<script>
import RFB from 'novnc-core'
export default {
  name: 'novnc',
  data() {
    return {
      rfb: null,
    }
  },
  methods: {
    updated: function(){
      if(this.config) this.rfb = new RFB(this.$refs.view, this.config.host+":"+this.config.port, {credentials:{password:this.config.password},scaleViewport: true});
    }
  },
  props: ['config'],
  watch: {
    config: {handler:function(){this.updated()}},
  }
}
</script>