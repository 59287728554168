<template>
  <div>
    <a href="https://www.damirscorner.com/blog/posts/20210528-AllowingInsecureWebsocketConnections.html">Follow this manual when the VNC viewer doesn't launch(you only get a black bar)</a><br>
    This will be fixed later, with a proxy.<br>
    <VM v-for="vm in vms" :key="vm" :name="vm"/>
  </div>
</template>

<script>
import Novnc from "@/components/novnc";
import VM from "@/components/VM";
export default {
  name: 'App',
  components: {Novnc, VM},
  data() {
    return {
      vms: null
    }
  },
  created: function() {
    fetch("/api").then(items=>items.json()).then(vms=>this.vms=vms);
  }
}
</script>
