<template>
  <div style="border: 1px solid black">
    {{name}}<button @click="load">load</button>
    <div v-if="config">
      <code>{{config}}</code>
      <div v-if="config.graphicsType==='vnc'">
        <novnc :config="novnc"/>
      </div>
      <button v-if="config.running" @click="post('shutdown')">acpiOff</button>
      <button v-if="config.running" @click="post('destroy')">forceOff</button>
      <button v-if="!config.running" @click="boot">boot</button>
      <button @click="()=>restore()">restore</button>
      <button v-if="config.config.allowreconnect && config.running && config.graphicsType==='vnc'" @click="connect">connect</button>
      <div v-if="config.running">
        <select v-model="disk">
          <option v-for="file in config.availableIsos" :value="file">{{file}}</option>
        </select>
        <button @click="insert">insert</button>
      </div>
    </div>
  </div>
</template>

<script>
import Novnc from "@/components/novnc";
export default {
  name: 'VM',
  components: {Novnc},
  props: ['name'],
  data() {
    return {
      config: null,
      novnc: null,
      disk: "",
    }
  },
  methods: {
    load: function() {
      fetch(`/api/${this.name}`).then(data=>data.json()).then(config=>this.config=config);
    },
    boot: function(){
      fetch(`/api/${this.name}/boot`,{method:"POST"}).then(data=>data.json()).then(novnc=>this.novnc=novnc);
    },
    post: function(action) {
      fetch(`/api/${this.name}/${action}`,{method:"POST"})
    },
    connect: function() {
      fetch(`/api/${this.name}/connect`).then(data=>data.json()).then(novnc=>this.novnc=novnc);
    },
    restore: function(snapshot) {
      fetch(`/api/${this.name}/snapshot/${snapshot||this.config.config.snapshot}`,{method:"POST"});
    },
    insert: function() {
      fetch(`/api/${this.name}/disk/${this.disk}`,{method:'POST'})
    }
  },
  mounted() {
    setInterval(this.load,5000);
    this.load();
  }
}
</script>
